@font-face {
	font-family: 'SF Pro Text Medium';
	src: local('SF Pro Text'), url(./assets/sf-pro-text-medium.woff) format('woff');
}

@font-face {
	font-family: 'SF Pro Text Regular';
	src: local('SF Pro Text'), url(./assets/sf-pro-text-regular.woff) format('woff');
}

@font-face {
	font-family: 'SF Pro Text Medium';
	src: local('SF Pro Text'), url(./assets/sf-pro-text-medium.woff) format('woff');
}

@font-face {
	font-family: 'SF Pro Text Regular';
	src: local('SF Pro Text'), url(./assets/sf-pro-text-regular.woff) format('woff');
}

:root {
	--nv-bg: #e5e5e5;
	--nv-dark: #10213E;


	--canvas: #E5E5E5;
	--dark: #011529;
	--dark-highlight: #323a54;
	--blue: #022140;
	--lightblue: #4cc5ff;
	--ant-lightblue: #1890ff;

}

body {
	margin: 0;
	line-height: 1;
	font-family: 'SF Pro Text Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

html {
	font-size: 62.5%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: separate;
	border-spacing: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* .k-pdf-export .k-chart {
	transform: scale(0.8, 0.8);
} */